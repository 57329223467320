import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { joinUrl } from 'utility/utility';

export function StaticCanonicalMetaTag() {
  const location = useLocation();
  return (
    <Helmet>
      <link rel="canonical" href={joinUrl(window.location.href.split('/').slice(0, 3).join('/'), location.pathname)} />
    </Helmet>
  );
}
