import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Utility } from '../../utility';
import { useLocale } from 'hooks/use-locale';
import Pako from 'pako';
import routeTranslations from 'assets/json/route-translations';
import { Routing } from './routing';
import { LoadingScreen } from 'components/loading-screen/loading-screen';
import { RedirectComponent } from 'utility/routing/redirect-component';

function resolveRedirectOptimized(inputPath, optimizedRedirects) {
  let currentPath = inputPath;
  for (let i = 0; i < optimizedRedirects.length; i++) {
    const rule = optimizedRedirects[i];

    if (rule[0] === 0) {
      // Literal rule: compare normalized values.
      if (normalizePath(currentPath) === rule[1]) {
        currentPath = rule[2];
      }
    } else {
      // Dynamic rule: the regex (stored in rule[1]) is the "from".
      const regex = new RegExp(rule[1]);
      const match = currentPath.match(regex);
      if (match) {
        let result = rule[3];
        // Replace placeholders in the target if necessary.
        if (result.indexOf(":") !== -1) {
          const paramNames = rule[2];
          for (let j = 0; j < paramNames.length; j++) {
            result = result.split(":" + paramNames[j]).join(match[j + 1]);
          }
        }
        currentPath = result;
      }
    }
  }
  return currentPath;
}

function normalizePath(path) {
  return path.length > 1 && path.endsWith("/") ? path.slice(0, -1) : path;
}

const translateRoute = (route, locale, fromLocale = 'en') => {
  locale ??= window.location.pathname.split('/')[1];
  if (!Utility.isLocaleValid(locale)) throw new Error('Invalid locale provided: ' + locale);

  const ogRoute = route;
  const routeParts = route.split('/').filter((x) => x && x !== '');
  if (routeParts[0].match(/^\w\w(-\w\w)?$/gim)) routeParts.splice(0, 1);
  route = routeParts.join('/');

  // translate route from fromLocale to english (en)
  let fromCurrent = routeTranslations[fromLocale];
  let routingCurrent = Routing;

  for (let i = 0; i < routeParts.length; i++) {
    const part = routeParts[i];

    routeParts[i] =
      Object.keys(fromCurrent)
        // eslint-disable-next-line no-loop-func
        .filter((key) => fromCurrent[key].__name === part)[0] ??
      Object.keys(fromCurrent).find((x) => x[0] === ':') ??
      part;

    fromCurrent =
      fromCurrent[part] ??
      Object.values(fromCurrent).find((x) => x.__name === part) ??
      Object.values(fromCurrent).find((x) => x.__name?.[0] === ':') ??
      routingCurrent[routeParts[i]];

    routingCurrent = routingCurrent[routeParts[i]];

    if (!fromCurrent && !routingCurrent && i + 1 !== routeParts.length)
      throw new Error('Invalid route or fromLocale provided: ' + ogRoute + ', ' + fromLocale);
  }

  route = routeParts.join('/');

  //translate route from english to locale
  const parts = route.split('/');
  routingCurrent = Routing;
  let current = routeTranslations[locale];
  route = '';
  for (const part of parts) {
    current = current?.[part];
    route += (current?.__name ?? part) + '/';
  }

  return '/' + locale + '/' + route;
};



/**
 * checks if a route is valid, takes the locale from the route if not provided
 * @param {string} route
 * @param {string | undefined} givenLocale
 * @returns {string | undefined} the locale of the route if valid, undefined otherwise
 */
const getRouteLanguage = (route, givenLocale = undefined) => {
  const checkedLocales = [];
  const parts = route.split('/').filter((x) => x && x !== '');

  const checkRouteOnLocale = (current, locale) => {
    if (checkedLocales.includes(locale) || !Utility.isLocaleValid(locale)) return false;
    checkedLocales.push(locale);

    for (const part of parts) {
      let englishKey = current
        ? // eslint-disable-next-line no-loop-func
          (Object.keys(current).filter((key) =>
            current[key].__name ? current[key].__name === part : key === part,
          )[0] ?? Object.keys(current).filter((key) => key[0] === ':')[0])
        : undefined;

      if (current) current = current[englishKey];
      if (!current) return false;
    }

    return current ? locale : undefined;
  };

  givenLocale = givenLocale?.toLowerCase();
  const routeLocale = parts[0].match(/^\w\w(-\w\w)?$/) ? parts.shift() : undefined;
  let isValid = false;

  isValid ||= checkRouteOnLocale(routeTranslations[routeLocale], routeLocale);

  isValid ||= checkRouteOnLocale(routeTranslations[givenLocale], givenLocale);

  isValid ||= checkRouteOnLocale(Routing, 'en');
  isValid ||= checkRouteOnLocale(routeTranslations.en, 'en');

  if (parts.length > 0 && !isValid) {
    const routeLanguageLocale = Object.keys(routeTranslations)
      .sort((x, y) => {
        const splittedX = x.split('-');
        const splittedY = y.split('-');

        let xCounter = 0;
        let yCounter = 0;
        if (splittedX.length === 1) xCounter += 2;
        if (splittedY.length === 1) yCounter += 2;

        if (splittedX[0] === splittedX[1]) xCounter += 1;
        if (splittedY[0] === splittedY[1]) yCounter += 1;

        return yCounter - xCounter;
      })
      .find((rtKey) =>
        Object.keys(routeTranslations[rtKey])
          .map((key) => routeTranslations[rtKey][key].__name ?? key)
          .includes(parts[0]),
      );

    if (routeLanguageLocale) {
      isValid = checkRouteOnLocale(routeTranslations[routeLanguageLocale], routeLanguageLocale);
    }
  }

  const locales = Object.keys(routeTranslations);
  for (let i = 0; !isValid && i < locales.length; i++) {
    const loc = locales[i];

    isValid = checkRouteOnLocale(routeTranslations[loc], loc);
  }
  return isValid;
};

let lastRedirect = null;

export const FallbackWithParams = () => {
  console.log('fallback with params');
  const location = useLocation();
  const locale = useLocale();
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const redirectWrapper = (route) => {
      // fill in the variables for the route
      if (route.includes('/:')) {
        const path = location.pathname.split('/').filter((x) => x && x.trim() !== '');
        const routeParts = route.split('/').filter((x) => x && x.trim() !== '');
        const offset = routeParts[0].match(/^\w\w(-\w\w)?$/) ? 1 : 0;

        for (let i = 0; i < path.length; i++) {
          if (routeParts[i + offset][0] === ':') {
            routeParts[i + offset] = path[i];
          }
        }

        route = routeParts.join('/');
      }

      if (route.endsWith('index.html')) route = route.substring(0, route.length - 10);
      if (!route.endsWith('/')) route += '/';
      console.log('redirecting to', route);

      // check if the route is going to be changed, else reload the page
      if (
        route
          .split('/')
          .filter((x) => x && x.trim() !== '')
          .join() !==
        location.pathname
          .split('/')
          .filter((x) => x && x.trim() !== '')
          .join()
      ) {
        // remove current link from history to prevent back button from redirecting to the same link
        // window.location.replace(route + (location.hash ?? '') + (location.search ?? ''));
        const redirRoute = route + (location.hash ?? '') + (location.search ?? '');
        if (redirRoute.startsWith('https://')) {
          setRedirectUrl(redirRoute);
        } else if (redirRoute.startsWith('/')) {
          setRedirectUrl(window.location.origin + redirRoute);
        } else {
          setRedirectUrl(window.location.origin + '/' + redirRoute);
        }
      } else {
        // window.location.reload();
      }
    };

    const bootstrap = async () => {
      const currentParts = location.pathname.split('/').filter((x) => x.trim() !== '');

      if (currentParts[0] !== '_') {
        const routeLanguage =
          currentParts.length > 0 && !currentParts[0]?.match(/^\w\w(-\w\w)?$/)
            ? getRouteLanguage(location.pathname, locale)
            : false;
        console.log('route lang: ' + routeLanguage);

        if (routeLanguage) {
          const translated = translateRoute(location.pathname, locale, routeLanguage);
          if (translated) return redirectWrapper(translated);
        }
      }

      const deflated = await fetch('/jsons/redirects.json.deflated', { cache: 'default' }).then((x) => x.arrayBuffer());
      /**
       * @type {[string, string][]}
       */
      const redirects = JSON.parse(Pako.inflate(deflated, { to: 'string' }));

      const resolved = resolveRedirectOptimized(location.pathname, redirects);
      const lastRedir = lastRedirect;
      lastRedirect = resolved;
      if (lastRedir && lastRedir === resolved) {
        lastRedirect = null;
        window.location.reload();
        return;
      };
      if (resolved === location.pathname) {
        redirectWrapper(`/${locale}/404/`);
      }else {
        redirectWrapper(resolved);
      }
    };
    if (locale) bootstrap();
  }, [location.pathname, locale]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      {/* <Helmet>{redirectUrl && <meta http-equiv="refresh" content={`0; url=${redirectUrl}`} />}</Helmet> */}
      {redirectUrl && <RedirectComponent to={redirectUrl} />}
      <LoadingScreen scale={1} />
    </div>
  );
};
