import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import LinkData from './Links.json';
import { Utility } from 'utility/utility';

function Footernew() {
  let para = useParams();
  let [linkarr, setlinkarr] = useState([]);
  let [wordarr, setwordarr] = useState({ data: [] });
  let [menu2, setmenu2] = useState(false);
  let [smallmenu, setsmallmenu] = useState(false);
  let params = useParams();
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    decidethepaths();
  }, []);

  useEffect(() => {
    if (params.count == 'en') {
      const popupShown = localStorage.getItem('popupShown');
      if (!popupShown && !Utility.isClientCrawler() && !Utility.isClientPrerender()) {
        setmenu2(true);
        localStorage.setItem('popupShown', 'true');
      }
    }
  }, []);

  useEffect(() => {
    if (menu2) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [menu2]);

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];

      if (para.count) {
        val = [para.count, 'en'];
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }

  let param = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  function togglehiddenmenus(value) {
    if (value == 1) {
      if (document.getElementById('footer-company-toggle-menu').classList.contains('hidefootermenu')) {
        document.getElementById('footer-company-toggle-menu').classList.remove('hidefootermenu');
        document.getElementById('footer-company-button').innerHTML =
          '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z" fill="#001020"/></svg>';
      } else {
        document.getElementById('footer-company-toggle-menu').classList.add('hidefootermenu');
        document.getElementById('footer-company-button').innerHTML =
          "<img width='16' height='16' src='https://strapiwmc4hstore.blob.core.windows.net/website-assets/minusicon.png'/>";
      }
    }
    if (value == 2) {
      if (document.getElementById('footer-information-toggle-menu').classList.contains('hidefootermenu')) {
        document.getElementById('footer-information-toggle-menu').classList.remove('hidefootermenu');
        document.getElementById('footer-information-button').innerHTML =
          '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z" fill="#001020"/></svg>';
      } else {
        document.getElementById('footer-information-toggle-menu').classList.add('hidefootermenu');
        document.getElementById('footer-information-button').innerHTML =
          "<img width='16' height='16' src='https://strapiwmc4hstore.blob.core.windows.net/website-assets/minusicon.png'/>";
      }
    }
    if (value == 3) {
      if (document.getElementById('footer-support-toggle-menu').classList.contains('hidefootermenu')) {
        document.getElementById('footer-support-toggle-menu').classList.remove('hidefootermenu');
        document.getElementById('footer-support-button').innerHTML =
          '<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z" fill="#001020"/></svg>';
      } else {
        document.getElementById('footer-support-toggle-menu').classList.add('hidefootermenu');
        document.getElementById('footer-support-button').innerHTML =
          "<img width='16' height='16' src='https://strapiwmc4hstore.blob.core.windows.net/website-assets/minusicon.png'/>";
      }
    }
  }

  let langdataEurope = [
    ['Austria', 'de-at', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/austria.jpg'],
    ['Belgium', 'nl-be', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/belgium.jpg'],
    ['Brazil', 'pt-br', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/brazil.jpg'],
    ['Canada', 'en-ca', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/canada.jpg'],
    ['Czech Republic', 'en-at', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/czech.jpg'],
    ['Denmark', 'da-dk', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/denmark.jpg'],
    ['Finland', 'fi-fi', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/finland.jpg'],
    ['France', 'fr-fr', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/france.jpg'],
    ['Germany', 'de-de', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/germany.jpg'],
    ['Ireland', 'en-ie', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/ireland.jpg'],
    ['Italy', 'it-it', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/italy.jpg'],
    ['Luxembourg', 'fr-lu', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/luxembourg.jpg'],
    ['Netherlands', 'nl-nl', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/netherlands.jpg'],
    ['Norway', 'nb-no', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/norway.jpg'],
    ['Poland', 'pl-pl', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/poland.jpg'],
    ['Portugal', 'pt-pt', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/portugal.jpg'],
    ['Slovakia', 'en-at', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/slovakia.jpg'],
    ['Spain', 'es-es', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/spain.jpg'],
    ['Sweden', 'sv-se', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/sweden.jpg'],
    ['Switzerland', 'de-ch', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/switzerland.jpg'],
    ['United States', 'en-us', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/usa.jpg'],
    ['United Kingdom', 'en-gb', 'https://strapiwmc4hstore.blob.core.windows.net/country-flags/brits.jpg'],
  ];
  function menu() {
    if (!menu2) return null;
    return (
      <div className="langtogglemenu" style={{ marginTop: '80px !important' }}>
        <div id="Language-Slide-Menu">
          <div className="LanguageSelect-InnerMenu">
            <button id="closelangsel" className="CloseFooterButton" onClick={() => setmenu2(false)}>
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.0006 11.9999C21.0006 12.1988 20.9216 12.3896 20.7809 12.5303C20.6403 12.6709 20.4495 12.7499 20.2506 12.7499H5.5609L11.0312 18.2193C11.1009 18.289 11.1562 18.3717 11.1939 18.4628C11.2316 18.5538 11.251 18.6514 11.251 18.7499C11.251 18.8485 11.2316 18.9461 11.1939 19.0371C11.1562 19.1281 11.1009 19.2109 11.0312 19.2806C10.9615 19.3502 10.8788 19.4055 10.7878 19.4432C10.6967 19.4809 10.5991 19.5003 10.5006 19.5003C10.402 19.5003 10.3045 19.4809 10.2134 19.4432C10.1224 19.4055 10.0396 19.3502 9.96996 19.2806L3.21996 12.5306C3.15023 12.4609 3.09491 12.3782 3.05717 12.2871C3.01943 12.1961 3 12.0985 3 11.9999C3 11.9014 3.01943 11.8038 3.05717 11.7127C3.09491 11.6217 3.15023 11.539 3.21996 11.4693L9.96996 4.7193C10.1107 4.57857 10.3016 4.49951 10.5006 4.49951C10.6996 4.49951 10.8905 4.57857 11.0312 4.7193C11.1719 4.86003 11.251 5.05091 11.251 5.24993C11.251 5.44895 11.1719 5.63982 11.0312 5.78055L5.5609 11.2499H20.2506C20.4495 11.2499 20.6403 11.3289 20.7809 11.4696C20.9216 11.6103 21.0006 11.801 21.0006 11.9999Z"
                  fill="#001020"
                />
              </svg>
            </button>
            <h2>
              {wordarr.data.length > 0 ? wordarr.data[0].attributes.FreeTitle1 : 'Please select your country/region'}
            </h2>
          </div>
          <div className="lang-button-wrapper">
            <div className="lang-dev-wrap" id="LanguageMenuFlags">
              {langdataEurope.map((item, index) => {
                return (
                  <>
                    <a key={'Key' + index} href={'/' + item[1] + '/'} hrefLang={item[1]}>
                      <div className="lang-card">
                        <img width={'24'} height={'18'} src={item[2]} alt={'flag' + item[1]} />
                        <div>
                          <h4>{item[0]}</h4>
                        </div>
                      </div>
                    </a>
                  </>
                );
              })}
            </div>

            <div className="lang-dev-wrap" id="LanguagemenuInternationalMenu">
              <a href={'/en/'} hrefLang="en">
                <div className="lang-card changecount-icon">
                  <svg width="80" height="60" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="60" fill="#04468c" />
                    <path
                      d="M40 10.5C36.1433 10.5 32.3731 11.6437 29.1664 13.7863C25.9596 15.929 23.4603 18.9745 21.9844 22.5377C20.5084 26.1008 20.1223 30.0216 20.8747 33.8043C21.6271 37.5869 23.4843 41.0615 26.2114 43.7886C28.9386 46.5157 32.4131 48.3729 36.1957 49.1253C39.9784 49.8777 43.8992 49.4916 47.4623 48.0156C51.0255 46.5397 54.071 44.0404 56.2137 40.8336C58.3564 37.6269 59.5 33.8567 59.5 30C59.4945 24.83 57.4383 19.8732 53.7826 16.2174C50.1268 12.5617 45.1701 10.5055 40 10.5ZM35.0556 37.5H44.9444C43.9375 40.9387 42.25 44.0381 40 46.4794C37.75 44.0381 36.0625 40.9387 35.0556 37.5ZM34.375 34.5C33.8775 31.5206 33.8775 28.4794 34.375 25.5H45.625C46.1225 28.4794 46.1225 31.5206 45.625 34.5H34.375ZM23.5 30C23.4987 28.4783 23.7088 26.9638 24.1244 25.5H31.3356C30.8881 28.4833 30.8881 31.5167 31.3356 34.5H24.1244C23.7088 33.0362 23.4987 31.5217 23.5 30ZM44.9444 22.5H35.0556C36.0625 19.0612 37.75 15.9619 40 13.5206C42.25 15.9619 43.9375 19.0612 44.9444 22.5ZM48.6644 25.5H55.8756C56.7081 28.4422 56.7081 31.5578 55.8756 34.5H48.6644C49.1119 31.5167 49.1119 28.4833 48.6644 25.5ZM54.6944 22.5H48.0513C47.2858 19.4878 46.0002 16.6325 44.2525 14.0625C46.4853 14.6625 48.5679 15.7232 50.3662 17.1764C52.1646 18.6295 53.6389 20.4429 54.6944 22.5ZM35.7475 14.0625C33.9998 16.6325 32.7143 19.4878 31.9488 22.5H25.3056C26.3611 20.4429 27.8354 18.6295 29.6338 17.1764C31.4321 15.7232 33.5147 14.6625 35.7475 14.0625ZM25.3056 37.5H31.9488C32.7143 40.5122 33.9998 43.3675 35.7475 45.9375C33.5147 45.3375 31.4321 44.2768 29.6338 42.8236C27.8354 41.3705 26.3611 39.5571 25.3056 37.5ZM44.2525 45.9375C46.0002 43.3675 47.2858 40.5122 48.0513 37.5H54.6944C53.6389 39.5571 52.1646 41.3705 50.3662 42.8236C48.5679 44.2768 46.4853 45.3375 44.2525 45.9375Z"
                      fill="white"
                    />
                  </svg>

                  <div>
                    <h4>International: English</h4>
                  </div>
                </div>
              </a>
              <a href={'/es/'} hrefLang="es">
                <div className="lang-card changecount-icon">
                  <svg width="80" height="60" viewBox="0 0 80 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="80" height="60" fill="#04468c" />
                    <path
                      d="M40 10.5C36.1433 10.5 32.3731 11.6437 29.1664 13.7863C25.9596 15.929 23.4603 18.9745 21.9844 22.5377C20.5084 26.1008 20.1223 30.0216 20.8747 33.8043C21.6271 37.5869 23.4843 41.0615 26.2114 43.7886C28.9386 46.5157 32.4131 48.3729 36.1957 49.1253C39.9784 49.8777 43.8992 49.4916 47.4623 48.0156C51.0255 46.5397 54.071 44.0404 56.2137 40.8336C58.3564 37.6269 59.5 33.8567 59.5 30C59.4945 24.83 57.4383 19.8732 53.7826 16.2174C50.1268 12.5617 45.1701 10.5055 40 10.5ZM35.0556 37.5H44.9444C43.9375 40.9387 42.25 44.0381 40 46.4794C37.75 44.0381 36.0625 40.9387 35.0556 37.5ZM34.375 34.5C33.8775 31.5206 33.8775 28.4794 34.375 25.5H45.625C46.1225 28.4794 46.1225 31.5206 45.625 34.5H34.375ZM23.5 30C23.4987 28.4783 23.7088 26.9638 24.1244 25.5H31.3356C30.8881 28.4833 30.8881 31.5167 31.3356 34.5H24.1244C23.7088 33.0362 23.4987 31.5217 23.5 30ZM44.9444 22.5H35.0556C36.0625 19.0612 37.75 15.9619 40 13.5206C42.25 15.9619 43.9375 19.0612 44.9444 22.5ZM48.6644 25.5H55.8756C56.7081 28.4422 56.7081 31.5578 55.8756 34.5H48.6644C49.1119 31.5167 49.1119 28.4833 48.6644 25.5ZM54.6944 22.5H48.0513C47.2858 19.4878 46.0002 16.6325 44.2525 14.0625C46.4853 14.6625 48.5679 15.7232 50.3662 17.1764C52.1646 18.6295 53.6389 20.4429 54.6944 22.5ZM35.7475 14.0625C33.9998 16.6325 32.7143 19.4878 31.9488 22.5H25.3056C26.3611 20.4429 27.8354 18.6295 29.6338 17.1764C31.4321 15.7232 33.5147 14.6625 35.7475 14.0625ZM25.3056 37.5H31.9488C32.7143 40.5122 33.9998 43.3675 35.7475 45.9375C33.5147 45.3375 31.4321 44.2768 29.6338 42.8236C27.8354 41.3705 26.3611 39.5571 25.3056 37.5ZM44.2525 45.9375C46.0002 43.3675 47.2858 40.5122 48.0513 37.5H54.6944C53.6389 39.5571 52.1646 41.3705 50.3662 42.8236C48.5679 44.2768 46.4853 45.3375 44.2525 45.9375Z"
                      fill="white"
                    />
                  </svg>
                  <div>
                    <h4>International: Spanish</h4>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div id="addcounts" className="hider">
            <br />
          </div>
        </div>
      </div>
    );
  }
  let smalllangmenu = useRef(null);
  useEffect(() => {
    if (document.querySelector('#SelectLangMenuSmall')) {
      document.querySelector('#SelectLangMenuSmall').style =
        'top:-' + document.querySelector('#SelectLangMenuSmall').clientHeight + 'px;position:absolute;';
      smalllangmenu.current.style = 'top:-' + smalllangmenu.current.clientHeight + 'px;position:absolute;';
      let widthy = document.querySelector('.smalllanguagemenu').clientWidth / 2 - 8;
      document.querySelector('.SelectLangMenuSmall-Square').style = 'left:' + widthy + 'px;';
    }
  }, [smallmenu]);

  function menusmall() {
    let languages = ['german', 'english'];
    let kurzel = [];
    let countrycode = params.count.split('-');
    let country = countrycode[1];
    if (country == 'de') {
      languages = ['German'];
      kurzel = ['de-de'];
    }
    if (country == 'gb') {
      languages = ['English'];
      kurzel = ['en-gb'];
    }
    if (country == 'ch') {
      languages = ['French', 'Deutsch', 'Italian'];
      kurzel = ['fr-ch', 'de-ch', 'it-ch'];
    }
    if (country == 'nl') {
      languages = ['Dutch'];
      kurzel = ['nl-nl'];
    }
    if (country == 'it') {
      languages = ['Italian'];
      kurzel = ['it-it'];
    }
    if (country == 'es') {
      languages = ['Spanish'];
      kurzel = ['es-es'];
    }
    if (country == 'lu') {
      languages = ['French'];
      kurzel = ['fr-lu'];
    }
    if (country == 'ie') {
      languages = ['English'];
      kurzel = ['en-ie'];
    }
    if (country == 'at') {
      languages = ['German', 'English'];
      kurzel = ['de-at', 'en-at'];
    }
    if (country == 'be') {
      languages = ['Dutch', 'French'];
      kurzel = ['nl-be', 'fr-be'];
    }
    if (country == 'dk') {
      languages = ['Danish'];
      kurzel = ['da-dk'];
    }
    if (country == 'no') {
      languages = ['Norwegian Bokmål'];
      kurzel = ['nb-no'];
    }
    if (country == 'se') {
      languages = ['Swedish'];
      kurzel = ['sv-se'];
    }
    if (country == 'fi') {
      languages = ['Finnish'];
      kurzel = ['fi-fi'];
    }
    if (country == 'pt') {
      languages = ['Portugese'];
      kurzel = ['pt-pt'];
    }
    if (country == 'pl') {
      languages = ['Polish'];
      kurzel = ['pl-pl'];
    }
    if (country == 'us') {
      languages = ['English', 'Spanish'];
      kurzel = ['en-us', 'es-us'];
    }
    if (country == 'br') {
      languages = ['Portugese'];
      kurzel = ['pt-br'];
    }
    if (country == 'ca') {
      languages = ['English', 'French'];
      kurzel = ['en-ca', 'fr-ca'];
    }
    if (country == 'fr') {
      languages = ['French'];
      kurzel = ['fr-fr'];
    }
    if (country == 'tr') {
      languages = ['Turkish'];
      kurzel = ['tr-tr'];
    }
    if (
      country != 'de' &&
      country != 'ch' &&
      country != 'gb' &&
      country != 'nl' &&
      country != 'it' &&
      country != 'es' &&
      country != 'lu' &&
      country != 'ie' &&
      country != 'at' &&
      country != 'be' &&
      country != 'dk' &&
      country != 'se' &&
      country != 'fi' &&
      country != 'pt' &&
      country != 'pl' &&
      country != 'us' &&
      country != 'br' &&
      country != 'ca' &&
      country != 'tr' &&
      country != 'fr' &&
      country != 'no'
    ) {
      languages = ['English', 'Spanish'];
      kurzel = ['en', 'es'];
    }

    if (smallmenu) {
      return (
        <>
          <div ref={smalllangmenu} className="smalllanguagemenu LanguagemenuFooter" id="SelectLangMenuSmall">
            {languages.map((item, index) => {
              return (
                <>
                  <button
                    key={'Key' + index}
                    className={params.count == kurzel[index] ? 'activelang' : 'normal'}
                    onClick={() => {
                      window.location.replace('/' + kurzel[index] + '/');
                    }}
                  >
                    {languages[index]}
                  </button>
                </>
              );
            })}

            <button
              id="changeregion"
              onClick={() => {
                setmenu2(true);
              }}
            >
              Change Region
            </button>
            <div className="SelectLangMenuSmall-Square"></div>
          </div>
        </>
      );
    }
  }
  useEffect(() => {
    decidethepaths();

    const cachedData = localStorage.getItem(para.count + '-cachedDataFooterData');
    if (cachedData) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      setwordarr(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(para.count + '-cachedDataFooterData');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=FooterText',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setwordarr(json);
          localStorage.setItem(
            para.count + '-cachedDataFooterData',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        })
        .then(adjustadress());
    }
  }, []);

  useEffect(() => {
    adjustadress();

    if (wordarr.data.length > 0) {
      setTimeout(() => {
        const officeElement = document.querySelector('.footer-second-row-offices-office1');

        if (officeElement) {
          const footerHeadline = document.getElementById('Footer-headline-adjustment');
          if (footerHeadline && footerHeadline) {
            footerHeadline.style = 'width:' + officeElement.clientWidth + 'px;';
          }
        }
      }, 0);
    }
  }, [wordarr]);

  function adjustheadline() {}

  function adjustadress() {
    if (wordarr.data.length > 0) {
      if (wordarr.data[0].attributes.FreeData3) {
        if (wordarr.data[0].attributes.FreeData3.data.length == 1) {
          document.querySelector('.footer-second-row-offices').style = 'display:block;float:right;';
          document.getElementById('footer-headline-offices').style =
            'width:100%;justify-content:flex-end;display:flex;';

          document.querySelector('.footer-second-row-right').style = 'padding-right:48px;';
        }
      }
    }
  }
  return (
    <div className="footer-wrap">
      <div className="footer-inner">
        {menu()}
        <div className="footer-first-row">
          <img
            src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-railing-white.svg'}
            alt="footer logo q-railing"
            width={90}
            height={24}
          />
        </div>
        <div
          className="footer-second-row"
          id={
            wordarr.data.length > 0 && wordarr.data[0].attributes.FreeData3.data.length > 2
              ? 'footer-second-row-3address-id'
              : ''
          }
        >
          <div className="footer-second-row-left">
            <div>
              <h4>{wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[0] : 'Company'}</h4>
              <ul>
                <li>
                  <Link to={'/' + para.count + linkarr[23]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[1] : 'About us'}
                  </Link>
                </li>
                <li>
                  <Link to={'/' + para.count + linkarr[19]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[2] : 'Careers'}
                  </Link>
                </li>
                <li>
                  <Link to={'/' + para.count + linkarr[12]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[3] : 'Contact us'}
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h4>{wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[23] : 'Information'}</h4>
              <ul>
                <li>
                  <Link to={'/' + para.count + '/news/'} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[4] : 'News'}
                  </Link>
                </li>
                <li>
                  <Link to={'/' + para.count + '/service/'} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[5] : 'Downloads'}
                  </Link>
                </li>

                <li>
                  <Link to={'/' + para.count + linkarr[14]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[8] : 'Trainigs'}
                  </Link>
                </li>
                {(para.count == 'de-de' || para.count == 'nl-nl' || para.count == 'en-gb') && (
                  <li>
                    <Link to={'/' + para.count + '/service/roadshow/'} hrefLang={para.count}>
                      Roadshow
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div>
              <h4>{wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[9] : 'Support'}</h4>
              <ul>
                <li>
                  <Link to={'/' + para.count + linkarr[11]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[9] : 'Support'}
                  </Link>
                </li>
                {para.count != 'fr-ch' && para.count != 'de-ch' && para.count != 'it-ch' && (
                  <>
                    <li>
                      <a hrefLang={para.count} href={'/' + para.count + '/service/ordering/'}>
                        {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[10] : 'Orders'}
                      </a>
                    </li>
                    <li>
                      <a hrefLang={para.count} href={'/' + para.count + '/service/payment/'}>
                        {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[11] : 'Payment'}
                      </a>
                    </li>
                    <li>
                      <a hrefLang={para.count} href={'/' + para.count + '/service/shipping/'}>
                        {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[12] : 'Shipping'}
                      </a>
                    </li>
                    <li>
                      <a hrefLang={para.count} href={'/' + para.count + '/service/returns/'}>
                        {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[13] : 'Returns'}
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className="footer-second-row-right">
            <h3 id="footer-headline-offices">
              <span id="Footer-headline-adjustment">
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[14] : 'Offices'}
              </span>
            </h3>
            <div
              className="footer-second-row-offices"
              id={
                wordarr.data.length > 0 &&
                wordarr.data[0].attributes.FreeData3.data.length > 2 &&
                'footer-second-row-3address'
              }
            >
              <div className="footer-second-row-offices-office1">
                {wordarr.data.length > 0 && wordarr.data[0].attributes.FreeData3 && (
                  <>
                    {wordarr.data[0].attributes.FreeData3.data[0].map((items) => {
                      return <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />;
                    })}
                  </>
                )}
              </div>
              {wordarr.data.length > 0 && (
                <>
                  {wordarr.data[0].attributes.FreeData3 && (
                    <>
                      {wordarr.data[0].attributes.FreeData3.data.length > 1 && (
                        <div className="footer-second-row-offices-office2">
                          <>
                            {wordarr.data[0].attributes.FreeData3.data[1].map((items) => {
                              return (
                                <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />
                              );
                            })}
                          </>
                        </div>
                      )}
                      {wordarr.data[0].attributes.FreeData3.data.length > 2 && (
                        <>
                          <div className="footer-second-row-offices-office3">
                            <>
                              {wordarr.data[0].attributes.FreeData3.data[2].map((items) => {
                                return (
                                  <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />
                                );
                              })}
                            </>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="footer-third-row">
          <div className="footer-third-row-icons">
            <a
              hrefLang={para.count}
              target="_blank"
              aria-label="Facebook-entry"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[15] : ''}
            >
              <img
                width={24}
                height={24}
                alt="FacebookIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/facebook_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              target="_blank"
              aria-label="Linkedin-entry"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[16] : ''}
            >
              <img
                width={24}
                height={24}
                alt="LinkedIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/linkedin_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              target="_blank"
              aria-label="Instagram-entry"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[17] : ''}
            >
              <img
                width={24}
                height={24}
                alt="InstagramIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/instagram_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              target="_blank"
              aria-label="Youtube-entry"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[18] : ''}
            >
              <img
                width={24}
                height={24}
                alt="YoutubeIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/youtube_white.svg"
              />
            </a>
            {wordarr.data.length > 0 && (
              <>
                {wordarr.data[0].attributes.FreeData2 && (
                  <a
                    hrefLang={para.count}
                    target="_blank"
                    aria-label="Whatsapp-entry"
                    href={wordarr.data.length > 0 ? wordarr.data[0].attributes.FreeData2.data[0] : ''}
                  >
                    <img
                      width={24}
                      height={24}
                      alt="WhatsappIcon"
                      src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/whatsapp_white.svg"
                    />
                  </a>
                )}
              </>
            )}
          </div>
          <div className="footer-third-row-buttons">
            <button
              onClick={() => {
                setmenu2(true);
              }}
            >
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.00685215 5.09862C0.00836563 5.3099 0.077776 5.51509 0.204835 5.6839C0.331894 5.85272 0.509867 5.9762 0.712477 6.03612L5.49748 7.50862L6.96998 12.2936C7.02999 12.4962 7.15349 12.6741 7.32228 12.8011C7.49108 12.9282 7.69622 12.9976 7.90748 12.9992H7.92623C8.13339 13.0012 8.33589 12.9378 8.50497 12.8181C8.67405 12.6984 8.80111 12.5284 8.8681 12.3324L12.9444 1.33799C12.9457 1.33475 12.9467 1.33141 12.9475 1.32799C13.0084 1.15102 13.0185 0.960486 12.9765 0.778082C12.9345 0.595678 12.8421 0.428721 12.7099 0.296205C12.5777 0.163689 12.411 0.0709332 12.2287 0.0284899C12.0464 -0.0139534 11.8559 -0.00438028 11.6787 0.0561199L11.6687 0.0592449L0.673727 4.13799C0.474643 4.20604 0.302536 4.33598 0.182556 4.50881C0.0625763 4.68164 0.00100994 4.88831 0.00685215 5.09862Z"
                  fill="white"
                />
              </svg>

              {para.count == 'de-de'
                ? 'Germany'
                : para.count == 'it-it'
                  ? 'Italy'
                  : para.count == 'en-gb'
                    ? 'United Kingdom'
                    : para.count == 'fr-fr'
                      ? 'France'
                      : para.count == 'en-us' || para.count == 'es-us'
                        ? 'USA'
                        : para.count == 'it-ch' || para.count == 'de-ch' || para.count == 'fr-ch'
                          ? 'Switzerland'
                          : para.count == 'da-dk'
                            ? 'Denmark'
                            : para.count == 'sv-se'
                              ? 'Sweden'
                              : para.count == 'nl-nl'
                                ? 'Netherlands'
                                : para.count == 'no-nb'
                                  ? 'Norway'
                                  : para.count == 'nl-be'
                                    ? 'Belgium'
                                    : para.count == 'fi-fi'
                                      ? 'Finland'
                                      : 'Global'}
            </button>
            <button
              onClick={() => {
                if (smallmenu) {
                  setsmallmenu(false);
                } else {
                  setsmallmenu(true);
                }
              }}
            >
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.5 7.1321C13.5002 8.25431 13.2099 9.35746 12.6574 10.3342C12.1048 11.3109 11.3087 12.1279 10.3467 12.7057C9.38466 13.2835 8.28942 13.6024 7.16759 13.6314C6.04575 13.6603 4.93554 13.3983 3.945 12.8708L1.81687 13.5802C1.64068 13.639 1.4516 13.6475 1.27083 13.6049C1.09006 13.5622 0.924742 13.47 0.793407 13.3387C0.662072 13.2074 0.569909 13.042 0.527248 12.8613C0.484588 12.6805 0.493115 12.4914 0.551875 12.3152L1.26125 10.1871C0.797593 9.31537 0.538623 8.34947 0.503998 7.36271C0.469374 6.37595 0.660003 5.39427 1.06142 4.49218C1.46283 3.5901 2.06448 2.79132 2.8207 2.15647C3.57691 1.52162 4.46782 1.0674 5.42579 0.828271C6.38376 0.589145 7.38362 0.571403 8.34948 0.776392C9.31533 0.981381 10.2218 1.40371 11.0001 2.01133C11.7783 2.61895 12.4079 3.39588 12.8411 4.28316C13.2743 5.17043 13.4996 6.14473 13.5 7.1321Z"
                  fill="white"
                />
              </svg>
              {para.count.match('de-')
                ? 'German'
                : para.count.match('en-')
                  ? 'English'
                  : para.count.match('pl-')
                    ? 'Polish'
                    : para.count.match('es-')
                      ? 'Spanish'
                      : para.count.match('fr-')
                        ? 'French'
                        : para.count.match('nl-')
                          ? 'Dutch'
                          : para.count.match('nb-')
                            ? 'Norwegian'
                            : para.count.match('it-')
                              ? 'Italian'
                              : para.count.match('pt-')
                                ? 'Portuguese'
                                : para.count.match('fi-')
                                  ? 'Finnish'
                                  : para.count == 'en'
                                    ? 'English'
                                    : 'Global'}
            </button>
            <button
              id="Footer-scrollupButton"
              aria-label="Scroll-Up-to-page-button-desktop"
              onClick={() => {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
              title="scroll up button"
            >
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.0306 6.662C10.9609 6.73192 10.8781 6.7874 10.787 6.82525C10.6958 6.8631 10.5981 6.88259 10.4993 6.88259C10.4006 6.88259 10.3029 6.8631 10.2117 6.82525C10.1206 6.7874 10.0378 6.73192 9.9681 6.662L6.74997 3.44387V12.6314C6.74997 12.8303 6.67095 13.021 6.5303 13.1617C6.38965 13.3024 6.19888 13.3814 5.99997 13.3814C5.80106 13.3814 5.61029 13.3024 5.46964 13.1617C5.32899 13.021 5.24997 12.8303 5.24997 12.6314V3.44387L2.0306 6.662C1.8897 6.80289 1.69861 6.88205 1.49935 6.88205C1.30009 6.88205 1.10899 6.80289 0.968098 6.662C0.827202 6.5211 0.748047 6.33001 0.748047 6.13075C0.748047 5.93149 0.827202 5.74039 0.968098 5.5995L5.4681 1.0995C5.53778 1.02958 5.62057 0.9741 5.71173 0.936246C5.8029 0.898392 5.90064 0.878906 5.99935 0.878906C6.09806 0.878906 6.1958 0.898392 6.28696 0.936246C6.37813 0.9741 6.46092 1.02958 6.5306 1.0995L11.0306 5.5995C11.1005 5.66918 11.156 5.75197 11.1938 5.84313C11.2317 5.9343 11.2512 6.03204 11.2512 6.13075C11.2512 6.22946 11.2317 6.3272 11.1938 6.41836C11.156 6.50953 11.1005 6.59232 11.0306 6.662Z"
                  fill="#001020"
                />
              </svg>
            </button>
            {menusmall()}
          </div>
        </div>
        <div className="footer-last-row">
          <div className="footer-last-row-copyright">
            <p>© 2006 - 2024 Q-railing</p>
          </div>
          <div className="footer-last-row-agb">
            <ul>
              {wordarr.data.length > 0 && wordarr.data[0].attributes.PageTranslations.data.length > 24 && (
                <li>
                  <a target="_blank" rel="noreferrer" href={wordarr.data[0].attributes.PageTranslations.data[24][1]}>
                    {wordarr.data[0].attributes.PageTranslations.data.length > 24
                      ? wordarr.data[0].attributes.PageTranslations.data[24][0]
                      : ''}
                  </a>
                </li>
              )}
              <li>
                <Link to={'/' + para.count + linkarr[29]} hrefLang={para.count}>
                  {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[19] : 'Private policy'}
                </Link>
              </li>
              <li>
                {/* eslint-disable-next-line no-script-url */}
                <a href={'#CCM.openWidget'} rel="nofollow" hrefLang={para.count}>
                  {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[20] : 'Cookies'}
                </a>
              </li>
              {para.count != 'en-us' &&
                para.count != 'nb-no' &&
                para.count != 'es-us' &&
                para.count != 'en-ca' &&
                para.count != 'fr-ca' && (
                  <li>
                    <Link to={'/' + para.count + linkarr[30]} hrefLang={para.count}>
                      {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[21] : 'Legal notice'}
                    </Link>
                  </li>
                )}
              <li>
                <Link to={'/' + para.count + '/terms/'} hrefLang={para.count}>
                  {wordarr.data.length > 0
                    ? wordarr.data[0].attributes.PageTranslations.data[22]
                    : 'Terms & conditions'}
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-mobile-design">
          <img
            id="mobile-footer-logo"
            src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-railing-white.svg'}
            alt="mobile footer logo"
            height={24}
            width={90}
          />
          <div className="footer-mobile-list">
            <h3
              onClick={() => {
                togglehiddenmenus(1);
              }}
            >
              {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[0] : 'Company'}
            </h3>{' '}
            <button
              aria-label="Plus-button-company"
              id="footer-company-button"
              onClick={() => {
                togglehiddenmenus(1);
              }}
            >
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z"
                  fill="#001020"
                />
              </svg>
            </button>
          </div>
          <ul id="footer-company-toggle-menu">
            <li>
              <Link to={'/' + para.count + linkarr[23]} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[1] : 'About us'}
              </Link>
            </li>
            <li>
              <Link to={'/' + para.count + linkarr[19]} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[2] : 'Careers'}
              </Link>
            </li>
            <li>
              <Link to={'/' + para.count + linkarr[12]} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[3] : 'Contact us'}
              </Link>
            </li>
          </ul>
          <div className="footer-mobile-list">
            <h3
              onClick={() => {
                togglehiddenmenus(2);
              }}
            >
              {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[23] : 'Information'}
            </h3>{' '}
            <button
              aria-label="Plus-button-information"
              id="footer-information-button"
              onClick={() => {
                togglehiddenmenus(2);
              }}
            >
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z"
                  fill="#001020"
                />
              </svg>
            </button>
          </div>
          <ul id="footer-information-toggle-menu">
            <li>
              <Link to={'/' + para.count + '/news/'} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[4] : 'News'}
              </Link>
            </li>
            <li>
              <Link to={'/' + para.count + '/service/'} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[5] : 'Downloads'}
              </Link>
            </li>

            <li>
              <Link to={'/' + para.count + '/service/trainings/'} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[8] : 'Trainings'}
              </Link>
            </li>
            {(para.count == 'de-de' || para.count == 'nl-nl' || para.count == 'en-gb') && (
              <li>
                <Link to={'/' + para.count + '/service/roadshow/'} hrefLang={para.count}>
                  Roadshow
                </Link>
              </li>
            )}
          </ul>
          <div className="footer-mobile-list">
            <h3
              onClick={() => {
                togglehiddenmenus(3);
              }}
            >
              {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[9] : 'Support'}
            </h3>{' '}
            <button
              id="footer-support-button"
              aria-label="Plus-button-Support"
              onClick={() => {
                togglehiddenmenus(3);
              }}
            >
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.25 8.13184C14.25 8.33075 14.171 8.52151 14.0303 8.66217C13.8897 8.80282 13.6989 8.88184 13.5 8.88184H8.75V13.6318C8.75 13.8307 8.67098 14.0215 8.53033 14.1622C8.38968 14.3028 8.19891 14.3818 8 14.3818C7.80109 14.3818 7.61032 14.3028 7.46967 14.1622C7.32902 14.0215 7.25 13.8307 7.25 13.6318V8.88184H2.5C2.30109 8.88184 2.11032 8.80282 1.96967 8.66217C1.82902 8.52151 1.75 8.33075 1.75 8.13184C1.75 7.93292 1.82902 7.74216 1.96967 7.60151C2.11032 7.46085 2.30109 7.38184 2.5 7.38184H7.25V2.63184C7.25 2.43292 7.32902 2.24216 7.46967 2.10151C7.61032 1.96085 7.80109 1.88184 8 1.88184C8.19891 1.88184 8.38968 1.96085 8.53033 2.10151C8.67098 2.24216 8.75 2.43292 8.75 2.63184V7.38184H13.5C13.6989 7.38184 13.8897 7.46085 14.0303 7.60151C14.171 7.74216 14.25 7.93292 14.25 8.13184Z"
                  fill="#001020"
                />
              </svg>
            </button>
          </div>
          <ul id="footer-support-toggle-menu">
            <li>
              <Link to={'/' + para.count + linkarr[11]} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[9] : 'Support'}
              </Link>
            </li>
            {para.count != 'fr-ch' && para.count != 'de-ch' && para.count != 'it-ch' && (
              <>
                <li>
                  <a hrefLang={para.count} href={'/' + para.count + '/service/ordering/'}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[10] : 'Orders'}
                  </a>
                </li>
                <li>
                  <a hrefLang={para.count} href={'/' + para.count + '/service/payment/'}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[11] : 'Payment'}
                  </a>
                </li>
                <li>
                  <a hrefLang={para.count} href={'/' + para.count + '/service/shipping/'}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[12] : 'Shipping'}
                  </a>
                </li>
                <li>
                  <a hrefLang={para.count} href={'/' + para.count + '/service/returns/'}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[13] : 'Returns'}
                  </a>
                </li>
              </>
            )}
          </ul>

          <div className="footer-mobile-offices">
            <h3>{wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[14] : 'Offices'}</h3>
            <div>
              <div className="footer-mobile-office-1">
                {wordarr.data.length > 0 && (
                  <>
                    {wordarr.data[0].attributes.FreeData3 && (
                      <>
                        {wordarr.data[0].attributes.FreeData3.data[0].map((items) => {
                          return <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />;
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
              {wordarr.data.length > 0 && (
                <>
                  {wordarr.data[0].attributes.FreeData3 && (
                    <>
                      {wordarr.data[0].attributes.FreeData3.data.length > 1 && (
                        <div className="footer-mobile-office-2">
                          <>
                            {wordarr.data[0].attributes.FreeData3.data[1].map((items) => {
                              return (
                                <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />
                              );
                            })}
                          </>
                        </div>
                      )}
                      {wordarr.data[0].attributes.FreeData3.data.length > 2 && (
                        <div className="footer-mobile-office-2">
                          <>
                            {wordarr.data[0].attributes.FreeData3.data[2].map((items) => {
                              return (
                                <div className="Footer-office-include" dangerouslySetInnerHTML={{ __html: items }} />
                              );
                            })}
                          </>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <ul id="footer-mobile-termsandcond">
            {wordarr.data.length > 0 && (
              <li>
                <a
                  target="_blank"
                  href={
                    wordarr.data[0].attributes.PageTranslations.data.length > 24
                      ? wordarr.data[0].attributes.PageTranslations.data[24][1]
                      : ''
                  }
                >
                  {wordarr.data[0].attributes.PageTranslations.data.length > 24
                    ? wordarr.data[0].attributes.PageTranslations.data[24][0]
                    : ''}
                </a>
              </li>
            )}
            <li>
              <Link to={'/' + para.count + linkarr[29]} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[19] : 'Private policy'}
              </Link>
            </li>
            <li>
              {/* eslint-disable-next-line no-script-url */}
              <a href={'#CCM.openWidget'} rel="nofollow">
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[20] : 'Cookies'}
              </a>
            </li>
            {para.count != 'en-us' &&
              para.count != 'nb-no' &&
              para.count != 'es-us' &&
              para.count != 'en-ca' &&
              para.count != 'fr-ca' && (
                <li>
                  <Link to={'/' + para.count + linkarr[30]} hrefLang={para.count}>
                    {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[21] : 'Legal notice'}
                  </Link>
                </li>
              )}
            <li>
              <Link to={'/' + para.count + '/terms/'} hrefLang={para.count}>
                {wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[22] : 'Terms & conditions'}
              </Link>
            </li>
          </ul>
          <div className="footer-third-row-buttons footer-mobile-row-buttons">
            <div className="footer-mobile-row-buttons-inner">
              <button
                onClick={() => {
                  setmenu2(true);
                }}
              >
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.00685215 5.09813C0.00836563 5.30941 0.077776 5.5146 0.204835 5.68342C0.331894 5.85223 0.509867 5.97571 0.712477 6.03563L5.49748 7.50813L6.96998 12.2931C7.02999 12.4957 7.15349 12.6736 7.32228 12.8007C7.49108 12.9277 7.69622 12.9971 7.90748 12.9988H7.92623C8.13339 13.0007 8.33589 12.9373 8.50497 12.8176C8.67405 12.6979 8.80111 12.5279 8.8681 12.3319L12.9444 1.33751C12.9457 1.33427 12.9467 1.33092 12.9475 1.32751C13.0084 1.15053 13.0185 0.959998 12.9765 0.777594C12.9345 0.59519 12.8421 0.428233 12.7099 0.295717C12.5777 0.163201 12.411 0.0704449 12.2287 0.0280016C12.0464 -0.0144417 11.8559 -0.00486856 11.6787 0.0556317L11.6687 0.0587566L0.673727 4.13751C0.474643 4.20556 0.302536 4.33549 0.182556 4.50832C0.0625763 4.68115 0.00100994 4.88782 0.00685215 5.09813Z"
                    fill="white"
                  />
                </svg>
                {para.count == 'de-de'
                  ? 'Germany'
                  : para.count == 'it-it'
                    ? 'Italy'
                    : para.count == 'en-gb'
                      ? 'United Kingdom'
                      : para.count == 'fr-fr'
                        ? 'France'
                        : para.count == 'en-us' || para.count == 'es-us'
                          ? 'USA'
                          : para.count == 'it-ch' || para.count == 'de-ch' || para.count == 'fr-ch'
                            ? 'Switzerland'
                            : para.count == 'da-dk'
                              ? 'Denmark'
                              : para.count == 'sv-se'
                                ? 'Sweden'
                                : para.count == 'nl-nl'
                                  ? 'Netherlands'
                                  : para.count == 'no-nb'
                                    ? 'Norway'
                                    : para.count == 'nl-be'
                                      ? 'Belgium'
                                      : para.count == 'fi-fi'
                                        ? 'Finland'
                                        : 'Global'}
              </button>

              <button
                onClick={() => {
                  if (smallmenu) {
                    setsmallmenu(false);
                  } else {
                    setsmallmenu(true);
                  }
                }}
              >
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M13.5 7.1321C13.5002 8.25431 13.2099 9.35746 12.6574 10.3342C12.1048 11.3109 11.3087 12.1279 10.3467 12.7057C9.38466 13.2835 8.28942 13.6024 7.16759 13.6314C6.04575 13.6603 4.93554 13.3983 3.945 12.8708L1.81687 13.5802C1.64068 13.639 1.4516 13.6475 1.27083 13.6049C1.09006 13.5622 0.924742 13.47 0.793407 13.3387C0.662072 13.2074 0.569909 13.042 0.527248 12.8613C0.484588 12.6805 0.493115 12.4914 0.551875 12.3152L1.26125 10.1871C0.797593 9.31537 0.538623 8.34947 0.503998 7.36271C0.469374 6.37595 0.660003 5.39427 1.06142 4.49218C1.46283 3.5901 2.06448 2.79132 2.8207 2.15647C3.57691 1.52162 4.46782 1.0674 5.42579 0.828271C6.38376 0.589145 7.38362 0.571403 8.34948 0.776392C9.31533 0.981381 10.2218 1.40371 11.0001 2.01133C11.7783 2.61895 12.4079 3.39588 12.8411 4.28316C13.2743 5.17043 13.4996 6.14473 13.5 7.1321Z"
                    fill="white"
                  />
                </svg>
                {para.count.match('de-')
                  ? 'German'
                  : para.count.match('en-')
                    ? 'English'
                    : para.count.match('pl-')
                      ? 'Polish'
                      : para.count.match('es-')
                        ? 'Spanish'
                        : para.count.match('fr-')
                          ? 'French'
                          : para.count.match('nl-')
                            ? 'Dutch'
                            : para.count.match('nb-')
                              ? 'Norwegian'
                              : para.count.match('it-')
                                ? 'Italian'
                                : para.count.match('pt-')
                                  ? 'Portuguese'
                                  : para.count.match('fi-')
                                    ? 'Finnish'
                                    : para.count == 'en'
                                      ? 'English'
                                      : 'Global'}
              </button>
              {menusmall()}
            </div>
            <button
              id="mobile-footer-scroll"
              aria-label="Scroll-Up-to-page-button"
              onClick={() => {
                window.scroll({ top: 0, left: 0, behavior: 'smooth' });
              }}
            >
              <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.0306 6.662C10.9609 6.73192 10.8781 6.7874 10.787 6.82525C10.6958 6.8631 10.5981 6.88259 10.4993 6.88259C10.4006 6.88259 10.3029 6.8631 10.2117 6.82525C10.1206 6.7874 10.0378 6.73192 9.9681 6.662L6.74997 3.44387V12.6314C6.74997 12.8303 6.67095 13.021 6.5303 13.1617C6.38965 13.3024 6.19888 13.3814 5.99997 13.3814C5.80106 13.3814 5.61029 13.3024 5.46964 13.1617C5.32899 13.021 5.24997 12.8303 5.24997 12.6314V3.44387L2.0306 6.662C1.8897 6.80289 1.69861 6.88205 1.49935 6.88205C1.30009 6.88205 1.10899 6.80289 0.968098 6.662C0.827202 6.5211 0.748047 6.33001 0.748047 6.13075C0.748047 5.93149 0.827202 5.74039 0.968098 5.5995L5.4681 1.0995C5.53778 1.02958 5.62057 0.9741 5.71173 0.936246C5.8029 0.898392 5.90064 0.878906 5.99935 0.878906C6.09806 0.878906 6.1958 0.898392 6.28696 0.936246C6.37813 0.9741 6.46092 1.02958 6.5306 1.0995L11.0306 5.5995C11.1005 5.66918 11.156 5.75197 11.1938 5.84313C11.2317 5.9343 11.2512 6.03204 11.2512 6.13075C11.2512 6.22946 11.2317 6.3272 11.1938 6.41836C11.156 6.50953 11.1005 6.59232 11.0306 6.662Z"
                  fill="#001020"
                />
              </svg>
            </button>
          </div>
          <div className="footer-third-row-icons">
            <a
              hrefLang={para.count}
              aria-label="Facebook-entry-responsive"
              target="_blank"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[15] : ''}
            >
              <img
                width={24}
                height={24}
                alt="FacebookIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/facebook_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              aria-label="Linkedin-entry-responsive"
              target="_blank"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[16] : ''}
            >
              <img
                width={24}
                height={24}
                alt="LinkedIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/linkedin_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              aria-label="Instagram-entry-responsive"
              target="_blank"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[17] : ''}
            >
              <img
                width={24}
                height={24}
                alt="InstagramIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/instagram_white.svg"
              />
            </a>
            <a
              hrefLang={para.count}
              aria-label="Youtube-entry-responsive"
              target="_blank"
              href={wordarr.data.length > 0 ? wordarr.data[0].attributes.PageTranslations.data[18] : ''}
            >
              <img
                width={24}
                height={24}
                alt="YoutubeIcon"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/youtube_white.svg"
              />
            </a>
            {wordarr.data.length > 0 && (
              <>
                {wordarr.data[0].attributes.FreeData2 && (
                  <a
                    hrefLang={para.count}
                    target="_blank"
                    aria-label="Whatsapp-entry"
                    href={wordarr.data.length > 0 ? wordarr.data[0].attributes.FreeData2.data[0] : ''}
                  >
                    <img
                      width={24}
                      height={24}
                      alt="WhatsappIcon"
                      src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/whatsapp_white.svg"
                    />
                  </a>
                )}
              </>
            )}
          </div>
          <div className="mobile-last-row">
            <p>© 2006 - 2024 Q-railing</p>
          </div>
        </div>
        {adjustheadline()}
      </div>
    </div>
  );
}

export default Footernew;
