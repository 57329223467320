import { lazy } from 'react';
import { Utility } from '../../utility';
import routeTranslations from '../../../assets/json/route-translations';

import ProfilePage from 'shop/customerManagement/ProfilePage';
import AdminBackend from 'shop/adminArea/AdminBackend';
import AdminCustomerDetail from 'shop/adminArea/AdminCustomerDetail';
import { CategoryManagement } from 'shop/category-management/category-management';
import { I18nManagement } from 'shop/i18n-management/i18n-management';

export const lazyLoadComponentRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

const VideoOverview = lazy(() => lazyLoadComponentRetry(() => import('../../../components/VideoOverview')));
const CategoryGridPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/CategoryGridPage')));
const NewsDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/NewsDetail')));
const KonfiguratorDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/KonfiguratorDetail')));
const Seopage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Seopage')));
const ProjectDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/ProjectDetail')));
const Architect = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Architect')));
const Installer = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Installer')));
const Consumer = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Consumer')));
const Systemdetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Systemdetail')));
const Prefix = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Prefix')));
const Roadshow = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Roadshow')));
const SystemTopCat = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SystemTopCat')));
const QdiscPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/QdiscPage')));
const SafetyPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SafetyPage')));
const SystemFinderPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SystemFinderPage')));

const SuccessPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SuccessPage')));
const DynamicBlockPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/DynamicBlockPage')));

const Mainpage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Mainpage')));
const Systems = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Systems')));
const Service = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Service')));

const Projectpage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Projectpage')));
const Aboutpage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Aboutpage')));
const Registerform = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/includes/Registerform')));

const Textpage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Textpage')));
const Jobcenter = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Jobcenter')));
const Jobcenterdetail = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Jobcenterdetail')));

const Downloads = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Downloads')));
const Contact = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/includes/Contact')));
const Page404 = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/Page404')));
const SearchresultsPage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/SearchresultsPage')));
const NewsOverview = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/NewsOverview')));

const TrainingsOverview = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/TrainingsOverview')));
const TrainingsDetail = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/TrainingsDetail')));
const CatalogPage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/CatalogPage')));
const InstallerRequest = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/InstallerRequest')));
const CategoriePage = lazy(() => lazyLoadComponentRetry(() => import ('../../../components/CategoriePage')));

export const Routing = {
  __component: Mainpage,
  __fallback: '404',
  404: {
    __component: Page404,
  },
  architect: {
    __component: Architect,
  },
  balustrades: {
    __component: Seopage,
    __$topic: 'railings',

    'balcony-railings': {
      __component: Seopage,
      __$topic: 'balcony',
    },
    'baluster-railings': {},
    'base-channels': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailBaseChannelsDisk',
    },
    'base-clamps': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailBaseClamps',
    },
    configurator: {
      __component: KonfiguratorDetail,
      ':title': {
        __component: KonfiguratorDetail,
      },
    },
    'glass-adapters': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailAdapters',
    },
    'glass-balustrades': {
      __component: Seopage,
      __$topic: 'glassrailing',
    },
    'terrace-railings': {
      __component: Seopage,
      __$topic: 'terrace-railing',
    },
    "blog":{
    'cleaning-and-maintenance': {
      __component: DynamicBlockPage,
      __$topic: 'cleaning-and-maintenance',
    },

    },


    'glass-clamps-and-connectors': {
      __component: CategoriePage,
      __$shopcategory: 'CategorypageGlassClamps',
    },
    handrails: {
      __component: SystemTopCat,
      __$category: 'FilterHandrails',
    },
    infills: {
      __component: CategoriePage,
      __$shopcategory: 'CategorypageInfills',
    },
    'juliet-balcony': {
      __component: SystemTopCat,
      __$category: 'FilterJulietBalconies',
    },
    'railing-safety': {
      __component: SafetyPage,
      __$topic: 'safety',
    },
    'stainless-steel-post-railing-systems': {
      __component: SystemTopCat,
      __$category: 'FilterStainlessSteelPosts',
    },
    'stair-railings': {
      __component: Seopage,
      __$topic: 'stairs',
    },
    systems: {
      __component: Systems,
      ':systems': {
        __component: Systemdetail,
      },
    },
    test: {
      __component: SafetyPage,
      __$topic: 'stairs',
    },
    systemfinder: {
      __component: SystemFinderPage,
    },
    'fire-rated-glass-balustrades': {
      __component: Seopage,
      __$topic: 'fire-rated-glass-balustrades',
    },
  },
  categories: {
    __component: CategoryGridPage,
  },
  career: {
    __redirect: '/company/career/',
    ':joboffer': {
      __redirect: '/company/career/:joboffer/',
    },
  },
  company: {
    'about-us': {
      __component: Aboutpage,
    },
    career: {
      __component: Jobcenter,
      ':joboffer': {
        __component: Jobcenterdetail,
      },
    },
  },
  consumer: {
    __component: Consumer,
  },
  cookies: {},
  inspiration: {
    __component: Projectpage,
    projects: {},
  },
  installer: {
    __component: Installer,
  },
  legal: {
    __component: Textpage,
    __$text: 'Text-content-Impressum',
  },
  news: {
    __component: NewsOverview,
    ':NewsSlug': {
      __component: NewsDetail,
    },
    events: {
      __component: NewsDetail,
    },
    roadshow: {
      __component: NewsDetail,
    },
  },
  ordering: {
    __component: Textpage,
    __$text: 'ordering-textpage',
  },
  payment: {
    __component: Textpage,
    __$text: 'payment-textpage',
  },
  'newsletter-confirm': {
    __component: SuccessPage,
    __$text: 'confirmed',
  },
  'newsletter-optinsubscribed': {
    __component: SuccessPage,
    __$text: 'optinsubscribed',
  },

  'privacy-policy': {
    __component: Textpage,
    __$text: 'Text-content-Datenschutz',
  },
  products: {
    __redirect: 'https://q-railing.com/',
  },
  projects: {
    __component: Projectpage,
    ':projectname': {
      __component: ProjectDetail,
    },
  },
  'q-disc': {
    __component: QdiscPage,
  },
  register: {
    __component: Registerform,
  },
  returns: {
    __component: Textpage,
    __$text: 'returns-textpage',
  },
  admin: {
    __component: AdminBackend,
    users: {
      __component: AdminBackend,
      ':id': {
        __component: AdminCustomerDetail,
      },
    },
    'category-management': {
      __component: CategoryManagement,
    },
    'i18n-management': {
      __component: I18nManagement,
    },
  },
  profile: {
    __component: ProfilePage,
    orders: {
      __component: ProfilePage,
      __$text: 'orders',
    },
    invoices: {
      __component: ProfilePage,
      __$text: 'invoices',
    },
  },
  service: {
    __component: Service,
    catalog: {
      __component: CatalogPage,
    },
    'catalogues-and-brochures': {
      __component: CatalogPage,
    },
    contact: {
      __component: Contact,
    },
    downloads: {
      __redirect: '/:locale/service/',
      'certificates-and-reports': {
        __component: Service,
      },
      ':dllcat': {
        __component: Downloads,
      },
    },
    'find-installer': {
      __redirect: '/service/find-an-installer/',
    },
    'find-an-installer': {
      __component: InstallerRequest,
    },
    ordering: {
      __component: Textpage,
      __$text: 'ordering-textpage',
    },
    payment: {
      __component: Textpage,
      __$text: 'payment-textpage',
    },
    prefix: {
      __component: Prefix,
    },
    returns: {
      __component: Textpage,
      __$text: 'returns-textpage',
    },
    roadshow: {
      __component: Roadshow,
    },
    shipping: {
      __component: Textpage,
      __$text: 'shipping-textpage',
    },
    trainings: {
      __component: TrainingsOverview,
      ':training': {
        __component: TrainingsDetail,
      },
    },
    videos: {
      __component: VideoOverview,
    },
  },
  systems: {
    __component: Systems,
    ':systems': {
      __component: Systemdetail,
    },
  },
  shipping: {
    __component: Textpage,
    __$text: 'shipping-textpage',
  },
  terms: {
    __component: Textpage,
    __$text: 'Text-content-Agbs',
  },
  search: {
    __component: SearchresultsPage,
    ':searchterm': {
      __component: SearchresultsPage,
    },
  },
};

/**
 * Trasnlates a route from any provided locale to english
 * @param {string} path path which is going to be translated
 * @param {string} originLocale locale of the path, if not provided will be taken from the path
 * @returns the trasnlated path without the locale
 */
export const translatePathToEnglish = (path, originLocale = 'en') => {
  path = path
    .split('/')
    .filter((x) => x && x !== '')
    .join('/');

  const parts = path.split('/');

  if (parts.length === 0) return '/';

  if (parts[0].match(/^\w\w(-\w\w)?$/gim)) {
    originLocale ??= parts[0];
    parts.shift();
  }

  if (!Utility.isLocaleValid(originLocale)) throw new Error('Invalid locale provided: ' + originLocale);

  if (originLocale === 'en') return '/' + parts.join('/') + (parts.length > 0 ? '/' : '');

  let current = routeTranslations[originLocale];
  let currentRouting = Routing;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];

    const c = current;
    const englishKey = current ? (Object.keys(current).find((x) => c[x]?.__name === part) ?? part) : part;

    current = current?.[englishKey]
      ? current[englishKey]
      : current
        ? current[Object.keys(current).find((x) => x[0] === ':')]
        : undefined;
    currentRouting = currentRouting?.[englishKey]
      ? currentRouting?.[englishKey]
      : currentRouting?.[Object.keys(currentRouting).find((x) => x[0] === ':')];
    parts[i] = englishKey;
  }

  return '/' + parts.join('/') + (parts.length > 0 ? '/' : '');
};

/**
 * translates a path without locale from english to a provided locale
 * @param {string} path the path to translate, has to be provided without locale
 * @param {string} locale the locale to translate to
 * @returns {string} the translated path with locale
 */
export const translatePathFromEnglish = (path, locale) => {
  if (!Utility.isLocaleValid(locale)) throw new Error('Invalid locale provided: ' + locale);
  path = path
    .split('/')
    .filter((x) => x && x !== '')
    .join('/');
  const parts = path.split('/');

  let current = routeTranslations[locale];

  for (let i = 0; i < parts.length && current; i++) {
    const part = parts[i];
    parts[i] = current[part]?.__name ?? part;
    current = current?.[part];
  }

  return '/' + locale + '/' + parts.join('/') + '/';
};

export const isRouteActive = (path, locale) => {
  path = path
    .split('/')
    .filter((x) => x && x !== '')
    .join('/');
  const parts = path.split('/');

  let active = true;
  let current = routeTranslations[locale];

  for (let i = 0; i < parts.length && current && active; i++) {
    const part = parts[i];
    active &&= current[part]?.__activated !== false;
    current = current?.[part];
  }

  return active;
};

export const translateRoute = (route, locale = undefined) => {
  const englishRoute = translatePathToEnglish(route);
  return isRouteActive(route, locale) ? translatePathFromEnglish(englishRoute, locale) : undefined;
};
