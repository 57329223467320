import { pushRedirectMetaCallback } from 'components/meta-tags/prerender-redirect-meta';
import { useEffect, useState } from 'react';
import { Utility } from 'utility/utility';
import { useLocation, useNavigate } from 'react-router-dom';

function isInternal(url) {
  try {
    const urlObj = new URL(url, window.location.href);
    return urlObj.host === window.location.host;
  } catch (e) {
    console.error('Invalid URL:', e);
    return false;
  }
}

function expandUrl(url) {
  if (isInternal(url)) {
    const urlObj = new URL(url, window.location.href);
    return urlObj.href;
  }
  return url;
}

const cleanUrl = (to) => {
  let cleanTo = expandUrl(to);
  let statusCode = 301;
  if (cleanTo === window.location.href) return;
  if (new URL(cleanTo).host !== window.location.host) {
    if (Utility.isClientPrerender() || Utility.isClientCrawler()) {
      pushRedirectMetaCallback(cleanTo, statusCode);
    } else {
      window.location.href = cleanTo;
    }
    return;
  }
  cleanTo = cleanTo.replace(window.location.origin, '');
  if (!cleanTo.endsWith('/')) cleanTo += '/';
  // change status code to 404 if redirected to it
  if (cleanTo.match(/(https?:\/\/([\w_\d-]+\.?)+(:\d+)?)?\/\w{2}(-\w{2})?\/404\/?$/)) {
    statusCode = 404;
  }
  // push to prerender meta tags
  pushRedirectMetaCallback(cleanTo, statusCode);
  return cleanTo;
};

export function redirect(to, navigate) {
  const cleanTo = cleanUrl(to);
  navigate(cleanTo, { replace: true });
}

export function RedirectComponent({ to }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const cleanTo = cleanUrl(to);
    navigate(cleanTo, { replace: true });
  }, [location]);
  return (
    <>
      {/* THIS CODE ALLOWS FOR JAVASCRIPT REDIRECTS BUT IS RELATIVLY SLOW COMPARED TO THE REACT SOLUTION*/}
      {/* <Helmet>{cleanTo && <meta http-equiv="refresh" content={`0; url=${cleanTo}`} />}</Helmet> */}
    </>
  );
}
