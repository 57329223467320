import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocale } from 'hooks/use-locale';
import { LoadingScreen } from 'components/loading-screen/loading-screen';
import { RedirectComponent } from '../redirect-component';
import { Utility } from 'utility/utility';

export const BaseRouter = () => {
  const navigate = useNavigate();
  const locale = useLocale();
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const redirect = (route) => {
      if (!route.endsWith('/')) route += '/';
      console.log('redirecting to', route);
      setRedirectUrl(route);
    };

    if (!locale) return;
    if (Utility.isClientCrawler() || Utility.isClientPrerender()) {
      redirect('/en/');
    } else {
      redirect(`/${locale}/`);
    }
  }, [locale, navigate]);

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <LoadingScreen scale={1} />
      {redirectUrl && <RedirectComponent to={redirectUrl} />}
    </div>
  );
};
